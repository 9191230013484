<template>
  <div>
    <vs-row vs-type="flex" vs-m="2">
      <invite-user
        class="m-3"
        :clinicCount="clinics"
        invitationType="Doctor"
        invitedBy="franchise"
      />
      <invite-user class="m-3" :clinicCount="clinics" invitationType="Nurse" invitedBy="franchise" />
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" vs-sm="3" vs-xs="12">
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Clinics</div>
          </template>
          <h1 class="count">{{clinics}}</h1>
        </vs-card>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" vs-sm="3" vs-xs="12">
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Doctors</div>
          </template>
          <h1 class="count">{{doctors}}</h1>
        </vs-card>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" vs-sm="3" vs-xs="12">
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Nurses</div>
          </template>
          <h1 class="count">{{nurses}}</h1>
        </vs-card>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" vs-sm="3" vs-xs="12">
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Patients</div>
          </template>
          <h1 class="count">{{patients}}</h1>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import inviteUser from "../../components/others/inviteUser";

export default {
  components: {
    inviteUser
  },
  data() {
    return {
      clinics: 0,
      doctors: 0,
      nurses: 0,
      patients: 0
    };
  },
  methods: {
    ...mapActions("franchise", ["getFranchiseDashboardData"]),
    getData() {
      const self = this;
      this.getFranchiseDashboardData().then(res => {
        self.clinics = res.data.data.clinics;
        self.doctors = res.data.data.doctors;
        self.nurses = res.data.data.nurses;
        self.patients = res.data.data.patients;
      });
    }
  },
  created() {
    this.getData();
  }
};
</script>
<style>
.count {
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}
.heading {
  font-size: large;
  font-weight: 600;
  color: black;
  /*margin-top: -5px;*/
  /*margin-bottom: -5px;*/
}
</style>
